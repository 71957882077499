import {
  Component,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {debounceTime, Subject} from 'rxjs';
import {TableSettings} from '../../../../../interfaces/table-setting.interface';
import {MatMultiSort, MatMultiSortTableDataSource, TableData} from 'ngx-mat-multi-sort';
import {NGXLogger} from 'ngx-logger';
import {takeUntil} from 'rxjs/operators';
import {TableCells} from '../table-cells.type';


/*
 * INFO: Mit dem Injection-Token lassen sich die Daten aus der spezifischen Tabellenkomponente in den generischen
 * TableWrapper übergeben. Das ist notwendig, da die TableWrapper-Komponente nicht weiß, welche Daten in der
 * spezifischen Tabellenkomponente verwendet werden.
 */
export const ELEMENT_DATA = new InjectionToken<any>('elementData');

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<TableItemDTO> implements OnChanges, OnDestroy {

  //#region inputs

  @Input()
  tableSettingsFallback?: TableSettings;

  @Input()
  tableSettings?: TableSettings;

  @Input()
  useMultisort: boolean = false;

  @Input()
  displayedItems: TableItemDTO[] = [];

  @Input()
  tableCells?: TableCells;

  @Output()
  changedTableSettings = new EventEmitter<TableSettings>();

  @Output()
  tableRowClicked = new EventEmitter<TableItemDTO>();

  //#region fields

  private readonly unsubscribe$ = new Subject<void>();

  private settings?: Element;

  // INFO: Der Injectoren, die die Daten der spezifischen Tabellenkomponente in die TableWrapper-Komponente überträgt.
  private injectorMap = new Map<string, Injector>();

  protected displayedColumns: string[] = [];

  // INFO: Initial mit Dummy-TableData setzen, um ggf. Restrictions zu umgehen.
  protected table?: TableData<TableItemDTO>;

  @ViewChild('tableContainer')
  public tableContainer: ElementRef | undefined;

  @ViewChild(MatMultiSort, {static: false})
  public matMultiSortTable: MatMultiSort | undefined;

  //#endregion Fields

  //#region Lifecycle

  constructor(
    private logger: NGXLogger,
    private elementRef: ElementRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.valueReallyChanged(changes, 'tableSettings') && this.tableSettings) {
      this.displayedColumns = this.tableSettings._columns
        ?.filter(column => column.isActive)
        ?.map(column => column.id) || [];

      // INFO: Zuerst die aktuellen Daten aus dem LocalStorage in den Store übertragen.
      this.readTableSettingsFromLS();

      // INFO: Nun die Daten aus dem Store nehmen und damit die Tabellenkonfiguration setzen.

      /*
       * INFO: Die Spaltenkonfiguration aus dem Store in die Tabelle übertragen.
       * Dabei eine Kopie erstellen, damit die Arrays NICHT readonly sind.
       */
      const tableSettings: TableSettings = {
        ...this.tableSettings,
        _sortDirs: [
          ...this.tableSettings._sortDirs,
        ],
        _sortParams: [
          ...this.tableSettings._sortParams,
        ],
      };

      /*
       * INFO: Tabelle initialisieren.
       * Versuchen, die Spaltenkonfiguration aus dem LocalStorage zu lesen und zu übernehmen.
       * Bei Fehlschlag wird die Tabelle mit den Standardwerten initialisiert.
       */
      try {
        /*
         * INFO: Wenn nach einer Spalte sortiert wird, die gerade ausgeblendet ist,
         * wird diese aus den Sort-Arrays entfernt, da es sonst zu einem Fehler kommt.
         */
        const columns = tableSettings._columns;
        for (const column of columns) {
          if (!column.isActive) {
            const index = tableSettings._sortParams.indexOf(column.id);
            if (index > -1) {
              tableSettings._sortParams.splice(index, 1);
              tableSettings._sortDirs.splice(index, 1);
            }
          }
        }

        this.table = new TableData<TableItemDTO>(
          /*
           * INFO:
           * Deep-Clone der Columns, um unsere Tabellenspalten-Konfiguration herzustellen,
           * ohne das ursprüngliche read-only Objekt aus der Library zu verändern.
           */
          structuredClone(tableSettings._columns),

          {
            defaultSortParams: tableSettings._sortParams,
            defaultSortDirs: tableSettings._sortDirs,
            localStorageKey: tableSettings._key,
          }
        );

      } catch (error) {
        this.logger.warn('Could not instantiate Table with localStorageKey. Using default values instead.', error);
        this.table = new TableData<TableItemDTO>(
          tableSettings._columns,
        );
      }

      // INFO: Tabelle mit der Komponente aus dem HTML DOM verknüpfen.
      setTimeout(() => {
        if (this.table && this.matMultiSortTable) {
          this.table.dataSource = new MatMultiSortTableDataSource(this.matMultiSortTable, false);
          this.table.dataSource.sort.ngOnChanges();
        }
      }, 0);

      /*
       * INFO: Sobald sich die Tabellenkonfiguration ändert, wird diese erneut in den Store übertragen.
       * Das findet asynchron statt (debounceTime), damit sichergestellt ist, dass die Daten zuerst im
       * LocalStorage aktualisiert werden und erst dann die readTableSettingsFromLS-Methode aufgerufen wird.
       */
      this.table.onColumnsChange().pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(0),
      ).subscribe(() => {
        this.readTableSettingsFromLS();
        this.moveSettingsGear();
      });

    }

    // Update des Wertes, nachdem die neuen Inhalte in der Tabelle fertig gerendert wurden.
    setTimeout(this.moveSettingsGear.bind(this), 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //#endregion Lifecycle

  //#region Methods


  /**
   * Erstellt und verwaltet eine Map von Injectoren, die auf der Grundlage von Daten und Spalten-ID erstellt werden.
   * Jeder Injector wird nur einmal erstellt und dann in der Map gespeichert, um Mehrfachinstanzen zu vermeiden.
   *
   * INFO: Mehrfachinstanzen würden (neben Leistungseinbußen) zu fehlerhaftem Verhalten führen, z.B. funktioniert dann
   * die Anzeige von Tooltips nicht mehr korrekt, da die ständig neue Tooltips erzeugt, die alten aber nicht mehr
   * rechtzeitig entfernt werden.
   *
   * @param {any} data - Die Daten, die in den Injector eingefügt werden sollen. Normalerweise handelt es sich dabei um
   * die Daten eines bestimmten Elements.
   * @param {string} columnId - Die ID der Spalte, die zusammen mit den Daten als Schlüssel für die Map dient.
   *
   * @returns {Injector} - Gibt den erstellten oder bereits vorhandenen Injector zurück.
   *
   * @example
   * const injector = getCellInjector(elementData, 'column1');
   *
   * @description
   * Diese Methode optimiert die Erstellung von Injectoren, indem sie bereits erstellte Injectoren in einer Map
   * speichert. Der Schlüssel für die Map ist eine Kombination aus `data.id` und `columnId`, und der Wert ist der
   * entsprechende Injector. Wenn ein Injector für ein bestimmtes Paar aus `data.id` und `columnId` bereits existiert,
   * wird dieser aus der Map abgerufen und zurückgegeben. Wenn kein solcher Injector existiert, wird ein neuer erstellt,
   * in der Map gespeichert und dann zurückgegeben. Dies stellt sicher, dass für jedes eindeutige Paar aus `data.id` und
   * `columnId` nur ein Injector erstellt wird, was die Leistung verbessert.
   */
  getCellInjector(data: any, columnId: string): Injector {
    const key = `${data.id}-${columnId}`;
    if (!this.injectorMap.has(key)) {
      const injector = Injector.create({
        providers: [
          {provide: ELEMENT_DATA, useValue: data},
        ],
      });
      this.injectorMap.set(key, injector);
    }
    return this.injectorMap.get(key) as Injector;
  }

  /**
   * Triggert das Starten der Sortierung einer einzelnen Spalte
   *
   * $event = Beinhaltete die Spalte und die Reihenfolge der Sortierung
   */
  onSortData(): void {
    if (!this.table) return;

    const sortParams = this.table.sortParams;
    const sortDirs = this.table.sortDirs;

    // INFO: Wenn nur Einfach-Sortierung gewünscht ist, wird nur die letzte Sortierung gespeichert.
    if (!this.useMultisort) {
      const deleteIndex = sortParams.length - 1;
      this.table.sortParams.splice(0, deleteIndex);
      this.table.sortDirs.splice(0, deleteIndex);
    }

    this.table.sortParams = sortParams;
    this.table.sortDirs = sortDirs;

    /*
     * INFO: Die in den LocalStorage geschriebenen tableSettings werden geprüft, ob sie dem single- oder multi-sort
     * Format entsprechen. Falls sie bei Single-Sort Konfiguration nicht dem entsprechenden Format entsprechen, wird die
     * nur die letzte Sortierung behalten, da es sonst zu einer fehlerhaften Darstellung der Sortierung kommt.
     * Das bearbeitete Objekt wird wieder in den LocalStorage übertragen.
     */
    try {
      const tableSettingsString = localStorage.getItem(this.tableSettingsFallback?._key || '') || '{}';
      let tableSettings: TableSettings = JSON.parse(tableSettingsString);

      if (!this.useMultisort) {
        const deleteIndex = tableSettings._sortParams.length - 1;
        tableSettings._sortParams.splice(0, deleteIndex);
        tableSettings._sortDirs.splice(0, deleteIndex);
        localStorage.setItem(this.tableSettingsFallback?._key || '', JSON.stringify(tableSettings));
      }

    } catch (error) {
      this.logger.warn('could not read table-settings from local storage', error);
    }

    this.readTableSettingsFromLS();
    this.moveSettingsGear();
  }

  readTableSettingsFromLS(): void {
    try {
      const tableSettingsString = localStorage.getItem(this.tableSettingsFallback?._key || '') || '{}';
      let tableSettings: TableSettings = JSON.parse(tableSettingsString);

      if (!tableSettings._columns) {
        tableSettings = this.tableSettingsFallback || {
          _key: '',
          _sortDirs: [],
          _sortParams: [],
          _columns: [],
        };
      }
      tableSettings = {
        ...tableSettings,
        _sortDirs: tableSettings._sortDirs,
        _sortParams: tableSettings._sortParams,
      };

      // INFO: Wenn nur Einfach-Sortierung gewünscht ist, wird nur die letzte Sortierung gespeichert.
      if (!this.useMultisort) {
        const deleteIndex = tableSettings._sortParams.length - 1;
        tableSettings._sortParams.splice(0, deleteIndex);
        tableSettings._sortDirs.splice(0, deleteIndex);
      }


      /*
       * INFO: Falls sich mal der Key einer Column ändern sollte oder neue hinzukommen,
       * werden im folgenden Abschnitt entfernte Columns aus den Localstorage-Settings
       * entfernt und neue hinzugefügt. Ansonsten würde es zu Fehlermeldungen kommen.
       */

      const filteredColumns = tableSettings._columns.filter(
        column => tableSettings._columns?.find(tColumn => tColumn.id === column.id)
      ) || [];

      const newColumns = tableSettings._columns?.filter(
        tColumn => !tableSettings._columns.find(column => tColumn.id === column.id)
      ) || [];

      filteredColumns.splice(-1, 0, ...newColumns);
      let filteredSortParams = [...tableSettings._sortParams];
      let filteredSortDirs = [...tableSettings._sortDirs];

      for (let i in tableSettings._sortParams) {
        if (!filteredColumns.find(column => column.id === tableSettings._sortParams[i])) {
          filteredSortParams.splice(parseInt(i), 1);
          filteredSortDirs.splice(parseInt(i), 1);
        }
      }

      tableSettings = {
        ...tableSettings,
        _columns: [
          ...filteredColumns,
        ],
        _sortParams: filteredSortParams,
        _sortDirs: filteredSortDirs,
      };

      this.changedTableSettings.emit(tableSettings);
    } catch (error) {
      this.logger.warn('could not read table-settings from local storage', error);
    }
  }

  /*
 * INFO: Das Einstellungsrad befindet sich normalerweise oberhalb der Tabelle. Es gibt aktuell noch keine Möglichkeit,
 * per Parameter in der Tabellen-Komponente einzustellen, dass es im Tabellenheader angezeigt werden soll.
 *
 * Es gibt daher zwei Wege, das Einstellungsrad in den Tabellenheader zu schieben:
 *
 * 1. Via CSS: Mit absoluter Positionierung und einem hohen z-index das Icon an die Stelle schieben. Problem:
 * Wenn Scrollbars auftreten oder sich die Position und Größer der Tabelle etwas ändern, ist es sehr aufwändig bis
 * unmöglich, das Icon so anzupassen, dass es sich weiterhin an die richtige Stelle in der Tabelle anfügt.
 *
 * 2. Via DOM Manipulation: Wir verschieben einfach per TS das HTML Element an die gewünschte stelle. Problem:
 * Sobald die Tabelle, bzw. die Tabellendaten neu gerendert werden, setzt sich die Position des Elements wieder zurück
 * und wir müssen das Element erneut verschieben.
 */
  moveSettingsGear(): void {
    // INFO: Erst verschieben, wenn die Tabelle geladen wurde. Vorher existiert das Ziel-Element (Tabellenheader) nicht.
    if (this.tableContainer) {

      // INFO: Das Settings-Element wird in einer Variable gespeichert, damit auf dieselbe Instanz referenziert wird.
      if (!this.settings) {
        this.settings = (this.elementRef.nativeElement.getElementsByTagName('mat-multi-sort-table-settings') as HTMLCollection).item(0) || undefined;
      }

      /*
       * INFO: Die Methode soll aufgerufen werden, sobald sich die Daten oder die Spalteneinstellungen ändern. Problem:
       * Wenn wir auf die Spaltenänderungen lauschen, wird die Methode immer vor dem Neu-Rendern der Tabelle ausgeführt.
       * Damit das Verschieben funktioniert, muss sie jedoch im Anschluss ausgelöst werden. Daher wird hier ein Timeout
       * verwendet, der das sicherstellt.
       */
      setTimeout(() => {
        /*
         * INFO: Zu diesem Zeitpunkt befindet sich das Icon nicht mehr im Tabellenheader, da die Tabelle neu gerendert
         * wurde. Es kann aber nicht sicher gesagt werden, wann der Tabellenheader bereit ist. Dafür stellt die
         * Komponente leider auch keinen Observer zur Verfügung. Daher wird hier in einem kurzen Interval solange
         * versucht das Element an die gewünschte Position zu setzen, bis es in der nächsten Iteration gefunden wurde.
         */
        const interval = setInterval(() => {
          const lastTableCol = document.getElementById('lastTableCol');
          if (!lastTableCol) return;

          const settingsInLastCol = lastTableCol.getElementsByTagName('mat-multi-sort-table-settings').length;
          if (settingsInLastCol > 0) {
            clearInterval(interval);
          } else if (this.settings && lastTableCol) {
            lastTableCol.appendChild(this.settings);
          }
        }, 10);
      }, 0);
    }
  }

  //#endregion Methods

  valueReallyChanged(changes: SimpleChanges, field: string): boolean {
    return changes[field] && JSON.stringify(changes[field].currentValue) !== JSON.stringify(changes[field].previousValue);
  }

  onRowClicked(item: TableItemDTO) {
    this.logger.debug('table-item-dto clicked', item);

    this.tableRowClicked.emit(item);
  }
}
