import {createAction, props} from '@ngrx/store';
import {EinheitDTO, UstProzentsatzDTO} from '../../openapi/fakturierung-openapi';


export class ProduktDialogActions {

  public static open = createAction(
    '[Produkte Table] Open produkt dialog.',
    props<{
      produktId: string | undefined;
    }>(),
  );

  public static close = createAction(
    '[Create Produkt Dialog] Close produkt dialog.',
  );

  public static readAvailableEinheiten = createAction(
    '[Create Produkt Dialog] Read available einheiten.',
  );

  public static readAvailableEinheitenSuccess = createAction(
    '[Create Produkt Dialog] Read available einheiten successfully.',
    props<{
      einheiten: EinheitDTO[];
    }>(),
  );

  public static readAvailableEinheitenFailure = createAction(
    '[Create Produkt Dialog] Read available einheiten failed.',
    props<{
      error: any;
    }>(),
  );

  public static readUmsatzsteuersaetze = createAction(
    '[Create Produkt Dialog] Read available Ust-Sätze.',
  );

  public static readUmsatzsteuersaetzeSuccess = createAction(
    '[Create Produkt Dialog] Read available Ust-Sätze successfully.',
    props<{
      umsatzsteuersaetze: UstProzentsatzDTO[];
    }>(),
  );

  public static readUmsatzsteuersaetzeFailure = createAction(
    '[Create Produkt Dialog] Read available Ust-Sätze failed.',
    props<{
      error: any;
    }>(),
  );
}
